import { Stack, Button } from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { email, document } from "../schemas";
import { SelectInput, TextInput } from "../components/inputs";

function Form({ campaings, submit }) {
  const schema = yup.object({
    campaign_id: yup
      .string()
      .min("1", "Necessário selecionar uma campanha")
      .required("Obrigatório"),
    document: document(),
    email: email(),
  });
  const methods = useForm({
    // mode: "all",
    defaultValues: { campaign_id: "", email: "" },
    resolver: yupResolver(schema),
  });
  const onSubmit = async (data) => {
    if (await submit(data)) {
      methods.reset();
      return;
    }
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Stack spacing={4} direction="column">
          <Stack spacing={2} direction="row">
            <SelectInput
              control={methods.control}
              title="Campanha"
              name="campaign_id"
              value=""
              config={{
                inputProps: {
                  displayEmpty: true,
                },
              }}
              options={
                campaings
                  ? [
                      {
                        label: "[Selecione uma campanha]",
                        value: null,
                      },
                      ...campaings.map((item) => {
                        return {
                          label: item.name,
                          value: item.id.toString(),
                        };
                      }),
                    ]
                  : [
                      {
                        label: "[Selecione uma campanha]",
                        value: null,
                      },
                    ]
              }
            />

            <TextInput
              control={methods.control}
              title="CPF"
              name="document"
              config={{
                type: "text",
                mask: "000.000.000-00",
                inputProps: { placeholder: "Informe seu CPF" },
              }}
            />

            <TextInput
              control={methods.control}
              title="E-mail"
              name="email"
              config={{
                type: "text",
                inputProps: { placeholder: "Informe seu e-mail" },
              }}
            />
          </Stack>
          <Button disabled={methods.formState.isSubmitting} type="submit">
            {methods.formState.isSubmitting ? "Processando..." : "Enviar"}
          </Button>
        </Stack>
      </form>
    </FormProvider>
  );
}

export default Form;
