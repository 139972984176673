import {
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  FormHelperText,
  Radio,
  FormGroup,
  Checkbox,
  TextField,
  Select,
  MenuItem,
} from "@mui/material";
import { IMaskInput } from "react-imask";
import { Controller } from "react-hook-form";
import { forwardRef } from "react";

function FormInputWrapper({ error, required, title, helperText, children }) {
  return (
    <FormControl error={error !== undefined} required={required}>
      <FormLabel>{title}</FormLabel>
      {children}
      {(error || helperText) && (
        <FormHelperText>{error?.message ?? helperText}</FormHelperText>
      )}
    </FormControl>
  );
}

const TextMaskCustom = forwardRef((props, ref) => {
  const { mask, onChange, ...rest } = props;
  return (
    <IMaskInput
      mask={mask}
      definitions={{
        "#": /[1-9.-\/]/,
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
      {...rest}
    />
  );
});

const TextInput = ({ control, config, name, ...props }) => {
  const { mask, inputProps } = config;

  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState: { error } }) => {
        if (mask && mask.length > 0) {
          return (
            <FormInputWrapper error={error} {...props}>
              <TextField
                {...field}
                {...inputProps}
                inputProps={{ mask: mask }}
                InputProps={{ inputComponent: TextMaskCustom }}
              />
            </FormInputWrapper>
          );
        }

        return (
          <FormInputWrapper error={error} {...props}>
            <TextField {...field} {...inputProps} />
          </FormInputWrapper>
        );
      }}
    />
  );
};

const RadioInput = ({ control, config, name, options, ...props }) => {
  const { inputProps } = config;

  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState: { error } }) => (
        <FormInputWrapper error={error} {...props}>
          <RadioGroup {...field}>
            {options.map((item, index) => (
              <FormControlLabel
                key={index}
                value={item.value}
                label={item.label}
                control={<Radio {...inputProps} />}
              />
            ))}
          </RadioGroup>
        </FormInputWrapper>
      )}
    />
  );
};

const CheckInput = ({ control, config, name, options, ...props }) => {
  const { inputProps } = config;

  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState: { error } }) => {
        return (
          <FormInputWrapper error={error} {...props}>
            <FormGroup>
              {options.map((item, index) => (
                <FormControlLabel
                  key={index}
                  label={item.label}
                  control={
                    <Checkbox
                      {...inputProps}
                      checked={field.value.includes(Number(item.value))}
                      onChange={() => {
                        const value = Number(item.value);

                        if (!field.value.includes(value)) {
                          field.onChange([...field.value, value]);
                          return;
                        }

                        const data = field.value.filter(
                          (value) => value !== value
                        );
                        field.onChange(data);
                      }}
                    />
                  }
                />
              ))}
            </FormGroup>
          </FormInputWrapper>
        );
      }}
    />
  );
};

const SelectInput = ({ control, config, name, options, ...props }) => {
  const { inputProps } = config;

  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState: { error } }) => (
        <FormInputWrapper error={error} {...props}>
          <Select
            {...inputProps}
            {...field}
            renderValue={(selected) => {
              if (options.length <= 0) return;

              if (!selected) {
                return (
                  <em>
                    {options.filter((item) => item.value === null)[0].label}
                  </em>
                );
              }

              return options.filter((item) => item.value === selected)[0].label;
            }}
          >
            {options.map((item, index) => (
              <MenuItem
                key={index}
                value={item.value}
                disabled={item.value === null}
              >
                {item.label}
              </MenuItem>
            ))}
          </Select>
        </FormInputWrapper>
      )}
    />
  );
};

export { TextInput, RadioInput, CheckInput, SelectInput };
