import { cloneElement } from "react";
import { RadioInput, CheckInput, TextInput } from "../components/inputs";

function DefaultInput({ title }) {
  return (
    <div style={{ display: "block" }}>
      <strong># (Não foi possível gerar o input) {title}</strong>
    </div>
  );
}

function RenderInput(props) {
  switch (props.config.type) {
    case "text":
      return <TextInput {...props} />;
    case "radio":
      return <RadioInput {...props} />;
    case "check":
      return <CheckInput {...props} />;
    default:
      return <DefaultInput {...props} />;
  }
}

function GenerateInput({ control, questions }) {
  return (
    <>
      {questions.map((question, index) => {
        if (!question.name || question.name.length < 0) {
          return <DefaultInput key={index} title={question.title} />;
        }

        const { config, ...rest } = question;

        const el = cloneElement(<RenderInput />, {
          key: index,
          control: control,
          config: config,
          ...rest,
        });

        return el;
      })}
    </>
  );
}

export default GenerateInput;
