async function organizeDataToForm({ section, questions }) {
  const schemas = await import("./schemas");

  var defaultValues = {};
  var schemasByValue = {};

  for (var question of questions) {
    defaultValues[question.name] = question.value;

    if (question.config && question.config.validation) {
      const [nf, ...rest] =
        typeof question.config.validation === "string"
          ? [question.config.validation]
          : question.config.validation;

      var args = [...rest];

      if (question.config.relational) {
        args.push(schemas[question.config.relational]());
      }

      schemasByValue[question.name] = schemas[nf](...args);
    }
  }

  return {
    section: section,
    questions: questions,
    defaultValues: defaultValues,
    schemas: schemasByValue,
  };
}

async function fetchData(endPoint, data, method) {
  const response = await fetch(process.env.REACT_APP_BASE_URL_API + endPoint, {
    method: method || "POST",
    headers: {
      "Content-Type": "application/json",
      "x-api-key": process.env.REACT_APP_X_API_KEY,
    },
    body: data ? JSON.stringify(data) : null,
  });
  const json = await response.json();
  return json;
}

const scrollToTop = () => {
  window.scrollTo({ top: 0, behavior: "smooth" });
};

export { organizeDataToForm, fetchData, scrollToTop };
