import { Stack, Typography } from "@mui/material";
import logo from "../logo.webp";

function HeaderForm() {
  return (
    <Stack spacing={2} marginBottom={4}>
      <Stack
        direction={{ base: "column", md: "row" }}
        gap={5}
        alignItems="center"
        justifyContent="center"
      >
        <img src={logo} height={100} alt="Lemann" />
        <Typography variant="h5" component="h1">
          Inscrição para o Programa de Formação de Lideranças Educacionais do
          Centro Lemann
        </Typography>
      </Stack>
      <Typography>
        Olá! Que bom ter você conosco no Programa de Formação de Lideranças
        Educacionais do Centro Lemann. Juntos, iniciaremos uma jornada de
        descobertas, aprendizados e ações para promover a equidade e melhorar a
        qualidade do ensino em sua rede.
      </Typography>
      <Typography>
        O objetivo deste formulário é registrar sua inscrição e conhecer você
        melhor. Queremos saber quem são as pessoas que participarão da formação
        para conseguir oferecer caminhos e conteúdos que façam a diferença na
        sua trajetória.
      </Typography>
      <Typography>
        A duração estimada de preenchimento é de 15 minutos.
      </Typography>
      <Typography>
        Respeitamos a sua privacidade! Todas as informações disponibilizadas
        serão de uso exclusivo do Centro Lemann de Liderança para Equidade na
        Educação, para oferecer a melhor experiência possível para você.
      </Typography>
    </Stack>
  );
}

export default HeaderForm;
