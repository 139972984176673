import { Box, Container, Alert, Button, Typography } from "@mui/material";
import { useState } from "react";
import HeaderForm from "./components/header-form";
import Start from "./form/start";
import Step from "./form/step";
import { organizeDataToForm, fetchData, scrollToTop } from "./utils";

var allData = [];

function ShowSteps({ paginate }) {
  if (!paginate) return null;

  return (
    <Typography
      variant="caption"
      display="block"
      textAlign="center"
      pt={1}
    >{`Você está no passo ${paginate.currentStep + 1} de ${
      paginate.totalSteps
    }`}</Typography>
  );
}

function App() {
  const [campaings, setCampaings] = useState(null);
  const [feedback, setFeedback] = useState(null); //{loading: false, error: false, message: ""}
  const [steps, setSteps] = useState([]);
  const [paginate, setPaginate] = useState(null);

  const getCampaings = async () => {
    try {
      const { meta, result } = await fetchData("setup/campaigns", null, "GET");

      if (meta.code === 200) {
        setCampaings(result);
        return;
      }
    } catch (error) {
      console.log(`getCampaings ${error}`);
    }
  };

  const getQuestions = async (nextStep = 0, data = {}) => {
    const params = {
      token: readCurrentToken(),
      step: nextStep,
      ...data,
    };

    const result = await fetchData("customer/quiz/check/token", params);

    const {
      meta,
      result: questions,
      step: currentStep,
      count_steps: totalSteps,
    } = result;

    if (meta.code < 300) {
      const dataForm = await organizeDataToForm(questions);
      var s = steps;
      s[currentStep] = {
        section: dataForm.section,
        questions: dataForm.questions,
        defaultValues: dataForm.defaultValues,
        schemas: dataForm.schemas,
        currentStep: currentStep,
        totalSteps: totalSteps,
        submit: controlForm,
      };
      setSteps(s);
      setPaginate({ currentStep, totalSteps });

      return;
    }

    clear();
    setSteps([]);
    setFeedback({
      loading: false,
      error: true,
      message: "Erro ao tentar iniciar o questionário. Tente mais tarde",
    });
  };

  const readCurrentToken = () => {
    const searchParams = new URLSearchParams(document.location.search);
    if (searchParams.get("token")) {
      return searchParams.get("token");
    }

    return null;
  };

  const controlForm = (data) => {
    allData[paginate.currentStep] = data;

    if (paginate.currentStep === paginate.totalSteps - 1) {
      onNext();
      return;
    }

    scrollToTop();
    getQuestions(paginate.currentStep + 1, data);
  };

  const mountFormStep = (step) => {
    const params = steps[step];
    return (
      <Step
        key={paginate.currentStep}
        section={params.section}
        questions={params.questions}
        defaultValues={
          allData[paginate.currentStep]
            ? allData[paginate.currentStep]
            : params.defaultValues
        }
        schemas={params.schemas}
        currentStep={params.currentStep}
        totalSteps={params.totalSteps}
        submit={controlForm}
      />
    );
  };

  const clear = () => {
    setSteps([]);
    setPaginate({ currentStep: 0, totalSteps: 0 });
    allData = [];
  };

  const onToken = async (data) => {
    setFeedback({ loading: true, error: false, message: "" });

    try {
      const result = await fetchData("customer", data);
      const { meta, result: message, error } = result;

      if (meta.code < 300) {
        setFeedback({ loading: false, error: false, message: message });
        return true;
      }

      setFeedback({ loading: false, error: true, message: error });
      return false;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  const onNext = async () => {
    try {
      var params = { token: readCurrentToken() };

      allData.forEach((obj) => {
        for (const prop in obj) {
          params[prop] = obj[prop];
        }
      });

      const result = await fetchData("customer/quiz/answer", params);
      const { meta, result: message } = result;

      if (meta.code < 300) {
        clear();
        setFeedback({ loading: false, error: false, message: message });
        return;
      }

      setFeedback({
        loading: false,
        error: true,
        message: "Erro ao submeter os dados. Tente mais tarde!",
      });
      return;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  useState(() => {
    getCampaings();
    if (readCurrentToken()) {
      getQuestions();
    }
  }, []);

  return (
    <Container maxWidth="md">
      <Box sx={{ my: 4 }}>
        <HeaderForm />
        {feedback && !feedback.loading ? (
          <Alert severity={feedback.error ? "error" : "warning"}>
            {feedback.message}
          </Alert>
        ) : (
          <>
            {paginate && paginate.currentStep > 0 && (
              <Box sx={{ mb: 4 }}>
                <Button
                  onClick={() => {
                    setPaginate((p) => {
                      return {
                        currentStep: p.currentStep - 1,
                        totalSteps: p.totalSteps,
                      };
                    });
                    scrollToTop();
                  }}
                >
                  {`Voltar para o passo ${paginate.currentStep}`}
                </Button>
                <ShowSteps paginate={paginate} />
              </Box>
            )}

            {!steps || steps.length === 0 ? (
              <Start campaings={campaings} submit={onToken} />
            ) : (
              mountFormStep(paginate.currentStep)
            )}
            <ShowSteps paginate={paginate} />
          </>
        )}
      </Box>
    </Container>
  );
}

export default App;
