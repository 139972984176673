import * as yup from "yup";
import { Typography, Stack, Button, Alert } from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import GenerateInput from "../components/generate-input";

function Step({
  section,
  questions,
  defaultValues,
  schemas,
  currentStep,
  totalSteps,
  submit,
}) {
  const methods = useForm({
    // mode: "all",
    defaultValues: { ...defaultValues },
    resolver: yupResolver(yup.object({ ...schemas })),
  });

  const onSubmit = async (data) => {
    if (await submit(data, currentStep, totalSteps)) {
      // methods.reset();
    }
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Stack spacing={4} direction="column">
          <Stack spacing={10}>
            <Stack spacing={4}>
              <Typography variant="h5" component="h2" m={0}>
                {section}
              </Typography>
              <GenerateInput control={methods.control} questions={questions} />
            </Stack>
          </Stack>

          {methods.formState.isSubmitted &&
            methods.formState.isDirty &&
            !methods.formState.isValid && (
              <Alert severity="error">Verifique o formulário.</Alert>
            )}
          <Button disabled={methods.formState.isSubmitting} type="submit">
            {methods.formState.isSubmitting
              ? "Processando..."
              : currentStep === totalSteps - 1
              ? `Finalizar`
              : `Ir para o passo ${currentStep + 2}`}
          </Button>
        </Stack>
      </form>
    </FormProvider>
  );
}

export default Step;
