import GothamBook from "./GothamBook.ttf";
import GothamMedium from "./GothamMedium.ttf";
import GothamLight from "./GothamLight.ttf";
import MyriadProBold from "./MyriadProBold.ttf";
import MyriadProRegular from "./MyriadProRegular.ttf";
import MyriadProLight from "./MyriadProLight.ttf";

const fonts = `
@font-face {
	font-family: 'GothamBook';
	font-style: normal;
	font-display: swap;
	src: local('GothamBook'), local('GothamBook'), url(${GothamBook}) format('truetype');
}

@font-face {
	font-family: 'GothamMedium';
	font-style: normal;
	font-display: swap;
	src: local('GothamMedium'), local('GothamMedium'), url(${GothamMedium}) format('truetype');
}

@font-face {
	font-family: 'GothamLight';
	font-style: normal;
	font-display: swap;
	src: local('GothamLight'), local('GothamLight'), url(${GothamLight}) format('truetype');
}

@font-face {
	font-family: 'MyriadProBold';
	font-style: normal;
	font-display: swap;
	src: local('MyriadProBold'), local('MyriadProBold'), url(${MyriadProBold}) format('truetype');
}

@font-face {
	font-family: 'MyriadProRegular';
	font-style: normal;
	font-display: swap;
	src: local('MyriadProRegular'), local('MyriadProRegular'), url(${MyriadProRegular}) format('truetype');
}

@font-face {
	font-family: 'MyriadProLight';
	font-style: normal;
	font-display: swap;
	src: local('MyriadProLight'), local('MyriadProLight'), url(${MyriadProLight}) format('truetype');
}
`;

export default fonts;
