import { createTheme } from "@mui/material/styles";

import fonts from "./fonts/index";

const theme = createTheme({
  typography: {
    fontFamily: "GothamBook",
    h5: { marginBottom: "1rem" },
    body1: {
      fontSize: "95%",
      color: "black",
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: fonts,
    },
    MuiFormControl: {
      defaultProps: {
        fullWidth: true,
        size: "small",
      },
    },
    MuiSelect: {
      autoWidth: true,
    },
    MuiTextField: {
      defaultProps: {
        fullWidth: true,
      },
    },
    MuiFormHelperText: {
      defaultProps: {
        sx: {
          margin: 0,
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiButton: {
      defaultProps: {
        fullWidth: true,
        size: "large",
        variant: "contained",
        disableElevation: true,
        sx: {
          backgroundColor: "rgba(255, 81, 0, 1)",
          "&:hover": {
            backgroundColor: "rgba(255, 81, 0, 0.8)",
          },
        },
      },
    },
  },
});

export default theme;
